import { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./pages/layout";
import Blog from "./components/Blog";
import Login from "./pages/login";
import UserPage from "./components/Userpage";
import PreviewPage from "./pages/PreviewPage";
import  Category from "./components/Category";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout isLoggedIn={isLoggedIn} />}>
            <Route
              path="/"
              element={isLoggedIn ? <Navigate to="/blog" replace /> : <Navigate to="/login" replace />}
            />
            <Route path="/blog" element={<Blog />} />
            <Route path="/category" element={<Category />} />
            <Route path="/userPage/:id" element={<UserPage />} />

          </Route>
        </Routes>
        <Routes>
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/previewPage/:id" element={<PreviewPage />} />
        </Routes>

      </BrowserRouter>

    </div>
  );
}

export default App;
