import React from "react";
import { FaBell } from "react-icons/fa";
import FlexBetween from "../components/FlexBetween";
import { AppBar, IconButton, Toolbar } from "@mui/material";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  return (
    <AppBar
     
      className="py-2"
      sx={{
        position: "static",
        background: "white",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }} className="">
        <FlexBetween>
          <FlexBetween>
            <div className="mt-1">
              <i class="bi bi-people text-black"></i>{" "}
              <span>
                <b className="text-black ms-2 fs-5">Blogs</b>
              </span>
            </div>
          </FlexBetween>
        </FlexBetween>

        <FlexBetween>
          <IconButton>
            <FaBell className="text-dark" />
          </IconButton>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
