import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './login.css';
import { toast } from 'react-toastify';

const Login = ({ onLogin }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState('');

  const onSubmit = async (data) => {
    try {
      const response = await axios.post('https://rowthtech.com/api/logIn', data);
      if (response.status === 200) {
        onLogin(); 
        navigate('/blog');
        toast.success('Login successful!', {
          autoClose: 2000, 
        });
      } else {
        setLoginError('Invalid credentials');
        toast.error('Invalid credentials', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error('Login error:', error);
      setLoginError('Login failed. Please try again.');
      toast.error('Login failed. Please try again.', {
        autoClose: 2000, 
      });
    }
  };

  return (
    <div className="container-fluid back-main">
      <div className='row'>
        <div className="login__card--container pb-5">
          <div className='col-12'>
            <p className="alt__login__text text-dark fs-5 mt-4 fw-bold">RowthTech Employee Login</p>
            <div className="manual__login">
              <form onSubmit={handleSubmit(onSubmit)} className="manual__login--form">
                <input
                  type="text"
                  placeholder="Email"
                  id="email"
                  {...register('email', {
                    required: 'Email is required',
                    validate: {
                      noSpaces: value => !/\s/.test(value) || 'Email should not contain spaces'
                    }
                  })}
                />
                {errors.email && <p className="error">{errors.email.message}</p>}
                <input
                  type="password"
                  placeholder="Password"
                  id="password"
                  {...register('password', {
                    required: 'Password is required',
                    validate: {
                      noSpaces: value => !/\s/.test(value) || 'Password should not contain spaces'
                    }
                  })}
                />
                {errors.password && <p className="error">{errors.password.message}</p>}
                <button type="submit" className="btn btn--manual mt-4">Log In</button>
                {loginError && <p className="error">{loginError}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
