import React, { useState, useEffect } from "react";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { Table, Button, Modal, Form, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import axios from "axios";
import { toast } from 'react-toastify';
import { GetCATEGORY, PostCATEGORY, PutCATEGORY, DeleteCATEGORY, ToggleStatusCATEGORY } from "../pages/api/Allapi";

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [currentCategory, setCurrentCategory] = useState({
    name: "",
    description: "",
    status: true,
  });
  const [errors, setErrors] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${GetCATEGORY}getCategory`);
      setCategories(response.data.response);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAdd = () => {
    setModalType("Add");
    setCurrentCategory({ id: null, name: "", description: "", status: true });
    setErrors({});
    setShowModal(true);
  };

  const handleEdit = (category) => {
    setModalType("Edit");
    setCurrentCategory(category);
    setErrors({});
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      await axios.delete(`${DeleteCATEGORY}/${id}`);
      setCategories(categories.filter((category) => category._id !== id));
      setShowDeleteModal(false);
      toast.success('Category Deleted Successfully', { autoClose: 2000 });
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error('Error Deleting Category', { autoClose: 2000 });
    } finally {
      setIsDeleting(false);
    }
  };

  const validateForm = () => {
    const errors = {};
    const nameRegex = /^[a-zA-Z\s]+$/;
    const descriptionRegex = /^[a-zA-Z\s.]+$/;

    if (!currentCategory.name.trim() || currentCategory.name.length < 2 || !nameRegex.test(currentCategory.name)) {
      errors.name = "Name must have at least 2 characters, cannot be empty, and should contain only letters and spaces.";
    }
    if (!currentCategory.description.trim() || currentCategory.description.length < 3 || !descriptionRegex.test(currentCategory.description)) {
      errors.description = "Description must have at least 3 characters, cannot be empty, and should contain only letters and spaces.";
    }
    return errors;
  };

  const validateEditForm = () => {
    const errors = {};
    const nameRegex = /^[a-zA-Z\s]+$/;
    const descriptionRegex = /^[a-zA-Z\s]+$/;

    if (!currentCategory.name.trim() || currentCategory.name.length < 2 || !nameRegex.test(currentCategory.name)) {
      errors.name = "Name must have at least 2 characters, cannot be empty, and should contain only letters and spaces.";
    }
    if (!currentCategory.description.trim() || currentCategory.description.length < 3 || !descriptionRegex.test(currentCategory.description)) {
      errors.description = "Description must have at least 3 characters, cannot be empty, and should contain only letters and spaces.";
    }
    return errors;
  };

  const handleSave = async () => {
    setIsSaving(true);
    let validationErrors;
    if (modalType === "Add") {
      validationErrors = validateForm();
    } else if (modalType === "Edit") {
      validationErrors = validateEditForm();
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsSaving(false);
      return;
    }

    try {
      let result;
      if (modalType === "Add") {
        result = await axios.post(PostCATEGORY, currentCategory);
      } else if (modalType === "Edit") {
        result = await axios.put(`${PutCATEGORY}/${currentCategory._id}`, currentCategory);
      }
      if (result.status === 202) {
        toast.error('Category Already Exists!', { autoClose: 2000 });
      } else {
        toast.success('Category Added Successfully', { autoClose: 2000 });
      }
      fetchData();
      setShowModal(false);
    } catch (error) {
      console.error("Error saving category:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newCategory = {
      ...currentCategory,
      [name]: type === "checkbox" ? checked : value,
    };
    setCurrentCategory(newCategory);

    // Revalidate the form on change
    const validationErrors = modalType === "Add" ? validateForm() : validateEditForm();
    setErrors(validationErrors);
  };

  const handleToggleStatus = async (category) => {
    try {
      const updatedCategory = { ...category, status: !category.status };
      await axios.patch(`${ToggleStatusCATEGORY}/${category._id}/status`, { status: updatedCategory.status });
      setCategories(
        categories.map((cat) =>
          cat._id === category._id ? updatedCategory : cat
        )
      );
      if (!category.status) {
        toast.success('Category has been successfully activated!', { autoClose: 2000 });
      } else {
        toast.success('Category has been successfully deactivated.', { autoClose: 2000 });
      }
    } catch (error) {
      console.error("Error updating category status:", error);
    }
  };

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + '...';
  };

  return (
    <>
      <div className="container-fluid py-md-4 border_shadow">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div>
              <h4 className="fw-bolder mb-0">Categories</h4>
            </div>
            <div>
              <Button className="bg-warning px-4 py-2" onClick={handleAdd}>
                &#x2b; Add Category
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-3 mt-3">
        <div className="row">
          <div className="col-12">
            <Table bordered hover className="">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {categories?.map((category, index) => (
                  <tr key={category._id}>
                    <td>{index + 1}</td>
                    <td>{truncateText(category.name, 20)}</td>
                    <td style={{minWidth:'100%',maxWidth:'100%'}}>
                      <OverlayTrigger
                        placement="top"
                        className='cursor_pointer'
                        overlay={
                          <Tooltip id={`tooltip-${category._id}`} className="custom-tooltip">
                            {category.description}
                          </Tooltip>
                        }
                      >
                        <span>{truncateText(category.description, 50)}</span>
                      </OverlayTrigger>
                    </td>
                    <td>
                      <Form.Check
                        type="switch"
                        id={`status-switch-${category._id}`}
                        checked={category.status}
                        onChange={() => handleToggleStatus(category)}
                      />
                    </td>
                    <td>
                      <CiEdit className="hover_buttons" onClick={() => handleEdit(category)} /> &nbsp;&nbsp; &nbsp;
                      <MdDelete className="hover_buttons" onClick={() => { setDeleteCategoryId(category._id); setShowDeleteModal(true); }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalType} Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name <sup className="text-danger">*</sup></Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={currentCategory.name}
                placeholder="Enter category name"
                onChange={handleChange}
                isInvalid={!!errors.name}
              />
              {errors.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Description <sup className="text-danger">*</sup></Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={currentCategory.description}
                placeholder="Enter category description"
                onChange={handleChange}
                isInvalid={!!errors.description}
              />
              {errors.description && <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="w-25" variant="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this category?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" className="w-25" onClick={() => handleDelete(deleteCategoryId)} disabled={isDeleting}>
            {isDeleting ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Category;
