import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Modal, Button, Form, Spinner, InputGroup } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import "./blog.css";
import { toast } from 'react-toastify';
import { DeleteAPI, GetAPI, PostAPI, PutAPI, GetCATEGORY } from "../pages/api/Allapi";
import Pagination from '@mui/material/Pagination';
import HtmlContent from './texttohtml';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [search, setSearch] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [productpagecount, setProductpagecount] = useState(1);
  const [categories, setCategories] = useState([]);
  const [searchcat, setSearchcat] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setFormData] = useState({
    mainHeading: "",
    categoryId: "",
    content: "",
    image: null,
    imageSmall: null,
    url: "",
    status: false,
  });
  const [loadbogs, setLoadbogs] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.get(`${GetCATEGORY}getCategory?status=true`);
      setCategories(response.data.response);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(GetAPI, {
          params: {
            page: currentPage,
            limit: itemsPerPage,
            search,
            categorys: searchcat,
          },
        });
        setBlogs(response.data.response);
        setProductpagecount(response.data.totalPages);
        setLoading(false);
      } catch (error) {
        setError("Error fetching data");
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, search, searchcat, loadbogs]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowDeleteModal = (id) => {
    setSelectedBlogId(id);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      const file = files[0];
      if (file && !file.type.startsWith("image/")) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: "Only image files are allowed",
        }));
        return;
      }
      setFormData((prevState) => ({
        ...prevState,
        image: file,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "",
      }));
    } else if (name === "imageSmall") {
      const file = files[0];
      if (file && !file.type.startsWith("image/")) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          imageSmall: "Only image files are allowed",
        }));
        return;
      }
      setFormData((prevState) => ({
        ...prevState,
        imageSmall: file,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        imageSmall: "",
      }));
    } else if (name === "url") {
      const urlPattern = /^[^\s/]+$/;
      if (!urlPattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          url: "URL cannot contain spaces or slashes (/)",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          url: "",
        }));
      }
      setFormData((prevState) => ({
        ...prevState,
        [name]: value.trim(),
      }));
    } else if (name === "mainHeading") {
      const slug = value.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
      setFormData((prevState) => ({
        ...prevState,
        mainHeading: value,
        url: slug,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        url: "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.mainHeading.trim()) newErrors.mainHeading = "Main Heading is required";
    if (!formData.categoryId.trim()) newErrors.categoryId = "Category is required";
    if (!formData.image) newErrors.image = "Image is required";
    if (!formData.url.trim()) newErrors.url = "URL is required";
    if (errors.url) newErrors.url = errors.url;
    if (errors.image) newErrors.image = errors.image;
    if (errors.imageSmall) newErrors.imageSmall = errors.imageSmall;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    setLoading(true);

    const prepareFormData = (formData) => {
      let heading = `<h2>${formData.mainHeading}<h2>`;
      const data = new FormData();
      data.append("mainHeading", heading);
      data.append("categoryId", formData.categoryId);
      data.append("content", "Enter Content");
      data.append("status", formData.status);
      data.append("url", formData.url);
      if (formData.image) {
        data.append("image", formData.image);
      }
      if (formData.imageSmall) {
        data.append("imageSmall", formData.imageSmall);
      }
      return data;
    };

    try {
      await axios.post(PostAPI, prepareFormData(formData), {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setLoadbogs(true);
      setFormData({
        mainHeading: "",
        categoryId: "",
        content: "",
        image: null,
        url: "",
        status: false,
      });
      handleCloseModal();
      setCurrentPage(1);
    } catch (error) {
      console.error("Error creating blog:", error);
    } finally {
      setLoading(false);
      setIsSubmitting(false);
    }
  };

  const handleStatusToggle = async (blog) => {
    const updatedStatus = !blog.status;
    try {
      await axios.put(`${PutAPI}/${blog._id}`, { status: updatedStatus });
      setBlogs((prevBlogs) =>
        prevBlogs?.map((b) =>
          b._id === blog._id ? { ...b, status: updatedStatus } : b
        )
      );
      if (!blog.status) {
        toast.success('Blog has been successfully activated!', {
          autoClose: 2000,
        });
      } else {
        toast.success('Blog has been successfully deactivated.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error updating blog status:", error.response ? error.response.data : error.message);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await axios.delete(`${DeleteAPI}/${selectedBlogId}`);
      const updatedBlogs = blogs.filter((blog) => blog._id !== selectedBlogId);
      setBlogs(updatedBlogs);
      handleCloseDeleteModal();
    } catch (error) {
      console.error("Error deleting blog:", error.response ? error.response.data : error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <>
      <div className="container-fluid bg-white mx-auto px-0">
        <Row className="mx-auto mx-sm-0 mt-2 bg-white py-3" style={{ borderRadius: "15px" }}>
          <Col xs={12} className="px-md-5 pb-3">
            <Row className="d-flex align-items-center justify-content-between">
              <div className="col-md-2">
                <div className="mt-1">
                  <i className="bi bi-people text-black"></i>{" "}
                  <span>
                    <b className="text-black fs-4">Blogs</b>
                  </span>
                </div>
              </div>
              <div className="col-xxl-6 col-8 d-flex flex-row justify-content-end">
                <button
                  onClick={handleShowModal}
                  className="buttons-rounded py-2 rounded px-4 align-items-center border-0 text-white fw-light dashboard-buttons backgroundColor"
                >
                  + Add New Blog
                </button>
                <Form.Select className="w-25 mx-4" onChange={(e) => setSearchcat(e.target.value)}>
                  <option value={''}>All</option>
                  {categories?.map(category => (
                    <option key={category._id} value={category._id}>{category.name}</option>
                  ))}
                </Form.Select>
                <input
                  type="search"
                  className="rounded border p-2"
                  placeholder="search..."
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
            </Row>
          </Col>

          <Col md={12} className="mt-md-4" style={{ maxHeight: '82.5vh', minHeight: '83vh', overflowY: 'scroll' }}>
            {loading ? (
              <div className="text-center mt-5">
                <Spinner animation="border" role="status" className="mt-5">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : error ? (
              <p className="text-danger text-center">{error}</p>
            ) : (
              <>
                {blogs.length === 0 ? (
                  <p className="text-center">No blogs found.</p>
                ) : (
                  <Row className="mx-md-3">
                    {blogs?.map((item, index) => (
                      <Col lg={6} className="mb-3 px-md-4" key={index}>
                        <Row className="d-flex justify-content-start align-items-start card_top px-md-3 py-md-4 px-2 py-2">
                          <Col lg={4} sm={4} xs={4} className="background_main_card_colsix">
                            <img src={item?.imageSmall} alt="rowthtech_img" />
                          </Col>
                          <Col lg={8} sm={8} xs={8} className="ps-md-5">
                            <Row className="d-flex flex-column justify-content-between align-items-center height_Card_main">
                              <Col lg={12} className="head_main px-md-0">
                                <HtmlContent characters={90} status={true} content={item?.mainHeading}></HtmlContent>
                              </Col>
                              <Col lg={12} className="content_main px-md-0">
                                <HtmlContent
                                  content={item?.content}
                                  status={true}
                                  characters={280}
                                ></HtmlContent>
                              </Col>
                              <Col lg={12} className="px-md-0 d-flex flex-row align-items-center justify-content-between">
                                <span className="textlink">
                                  <Link
                                    to={{ pathname: `/previewPage/${item?._id}`, state: { blogs } }}
                                    className="nav-link navlinkkk text-dark"
                                  >
                                    Read More &gt;&gt;
                                  </Link>
                                </span>
                                <div className="d-flex flex-row gap-3">
                                  <CiEdit className="mt-1 read_more" onClick={() => navigate(`/userPage/${item?._id}`)} />
                                  <MdDelete className="mt-1 read_more" onClick={() => handleShowDeleteModal(item?._id)} />
                                  <div className="form-check form-switch pt-0 read_more">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id={`flexSwitchCheckDefault-${item?._id}`}
                                      checked={item?.status}
                                      onChange={() => handleStatusToggle(item)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                        </Row>
                      </Col>
                    ))}
                  </Row>
                )}
              </>
            )}
          </Col>
        </Row>
        <Row className="mx-auto mx-sm-0 pb-3">
          <Col xs={12} className="d-flex justify-content-center">
            <Pagination
              count={productpagecount}
              page={currentPage}
              onChange={handlePaginationChange}
              color="warning"
            />
          </Col>
        </Row>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="mainHeading">
              <Form.Label>Main Heading <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter main heading"
                name="mainHeading"
                value={formData.mainHeading}
                onChange={handleChange}
                isInvalid={!!errors.mainHeading}
              />
              <Form.Control.Feedback type="invalid">
                {errors.mainHeading}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="categoryId" className="mt-3">
              <Form.Label>Category <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                as="select"
                name="categoryId"
                value={formData.categoryId}
                onChange={handleChange}
                isInvalid={!!errors.categoryId}
              >
                <option value="">Select category</option>
                {categories.map(category => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.categoryId}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="url" className="mt-3">
              <Form.Label>URL <span style={{ color: 'red' }}>*</span></Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">{`https://www.rowthtech.com/blog/`}</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Enter URL"
                  name="url"
                  value={formData.url}
                  onChange={handleChange}
                  aria-describedby="basic-addon3"
                  isInvalid={!!errors.url}
                />
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {errors.url}
              </Form.Control.Feedback>  
            </Form.Group>

            <Form.Group controlId="image" className="mt-3">
              <Form.Label>Image For Read-More Page <span className="text-danger fw-bold">(1079 × 599 px)</span> <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleChange}
                isInvalid={!!errors.image}
              />
              <Form.Control.Feedback type="invalid">
                {errors.image}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="imageSmall" className="mt-3">
              <Form.Label>Image For Blog Page <span className="text-danger fw-bold">(180 × 112 px)</span> <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                type="file"
                name="imageSmall"
                onChange={handleChange}
                isInvalid={!!errors.imageSmall}
              />
              <Form.Control.Feedback type="invalid">
                {errors.imageSmall}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="status" className="mt-3">
              <Form.Check
                type="checkbox"
                label="Active"
                name="status"
                checked={formData.status}
                onChange={(e) => setFormData((prevState) => ({
                  ...prevState,
                  status: e.target.checked,
                }))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this blog?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" className="w-25 fw-bold" onClick={handleDelete} disabled={isDeleting}>
            {isDeleting ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Blog;