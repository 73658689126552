import React, { useState, useEffect } from "react";
import "../pages/Navbar.css";
import { NavLink, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  const handlePhoneClick = () => navigate("/rtgit");
  const [industryDropdown, setIndustryDropdown] = useState(false);
  const [topIndDropdown, setTopIndDropdown] = useState(false);
  const [btnHideShow, setBtnHideShow] = useState(false);

  let activeStyle = {
    Color: "orange",
    textDecoration: "none",
  };

  useEffect(() => {
    const cl = window.addEventListener(
      "click",
      (e) => {
        if (e.target.id !== "dropdownMenuLink") {
          setshowDropdown(false);
          setTopIndDropdown(false);
        }
      },
      false
    );
  }, []);
  const [showDropdown, setshowDropdown] = useState(false);
  return (
    <div className="fixed" style={{position:"sticky", top:"0"}}>
      <header className="container-fluid header_container shadow-none py-md-2">
        <nav className="navbar d-block navbar-expand-lg  custom-container">
          <div className="row nav_pos align-items-center">
            <div className="col d-flex justify-content-md-start justify-content-sm-start">
              <p to="/" className=" navbar-brand m-0 ms-sm-0 ms-md-0">
                <img
                  className="rowth_logo header_logo_image  "
                  src="https://rowthtech.s3.amazonaws.com/logo.png"
                  alt="rowthtech_img"
                />
              </p>
            </div>
            <div className="col-12 nav_des d col-md-9 col-xl-9 navbar_HeadFont d-flex align-items-center justify-content-start">
              <div className="collapse navbar-collapse" id="navbarNavdesktop">
                <ul className="navbar-nav mx-md-5 link_container flex-grow-2 fw-bold d-flex align-items-center justify-content-start gap-5">
                  <div className="col-auto nav-item p-0 m-md-0 mb-2 ">
                    <NavLink
                     
                      className="nav-link  text-uppercase text-black "
                    >
                      Home
                    </NavLink>
                  </div>
                  <div className="col-auto nav-item p-0 m-md-0 mb-2 ">
                    <NavLink
                    
                      className="nav-link  text-uppercase text-dark "
                    >
                      About Us
                    </NavLink>
                  </div>
                  <div className="col-auto nav-item p-0 m-md-0 mb-2 ">
                    <NavLink className="nav-link  text-uppercase text-dark">
                      Services
                    </NavLink>
                  </div>
                  <div className="dropdown col-auto nav-item  p-0 m-md-0 mb-2 ">
                    <p
                      className=" p-0 m-0 border-0 "
                      role="button"
                      id="dropdownMenuLink"
                    >
                      INDUSTRIES
                    </p>
                  </div>

                  <div className="col-auto nav-item p-0 m-md-0 mb-2">
                    <NavLink className="nav-link  text-uppercase text-dark">
                      Careers
                    </NavLink>
                  </div>
                  <div className="col-auto nav-item p-0 m-md-0 mb-2">
                    <NavLink
                      className="nav-link  text-uppercase text-dark"
                      href="#"
                    >
                      Portfolio
                    </NavLink>
                  </div>

                  <div className="dropdown col-auto nav-item  p-0 m-md-0 mb-2 ">
                    <p
                      className=" p-0 m-0 border-0 "
                      to="#"
                      role="button"
                      id="dropdownMenuLink"
                    >
                      OUR PRODUCT
                    </p>
                  </div>
                </ul>

                <div className="nav-icons flex-grow-2 d-flex align-items-center  ms-md-auto">
                  <div className="search-icon"></div>
                  <p>
                    <button
                      type="button"
                      className="btn btn-dark d-flex align-items-center justify-content-center rounded-pill custom_width_for_call_icon p-2 p-xl-1 ms-3 ms-xl-4"
                    >
                      <img
                        className="phone_icon  custom_button_style_call"
                        src="https://rowthtech.s3.amazonaws.com/phoneIcon.png"
                        alt="rowthtech_img"
                      />
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
