// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blogHeadSocial {
  list-style: none;
  height: 40px;
}

.blogHeadSocial .img {
  width: 30px;
}
.blogHeadSocial .share{
  width: 40px;
}
.blogHeadSocial img:hover {
  width: 35px;

}

.main_lines {
  cursor: pointer;
  border-top: 1px solid rgba(34, 23, 99, 0.1);
  border-bottom: 1px solid rgba(34, 23, 99, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/pages/PreviewPage.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;;AAEb;;AAEA;EACE,eAAe;EACf,2CAA2C;EAC3C,8CAA8C;AAChD","sourcesContent":[".blogHeadSocial {\n  list-style: none;\n  height: 40px;\n}\n\n.blogHeadSocial .img {\n  width: 30px;\n}\n.blogHeadSocial .share{\n  width: 40px;\n}\n.blogHeadSocial img:hover {\n  width: 35px;\n\n}\n\n.main_lines {\n  cursor: pointer;\n  border-top: 1px solid rgba(34, 23, 99, 0.1);\n  border-bottom: 1px solid rgba(34, 23, 99, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
