
// const API = 'http://localhost:8081/api/';
const API = "https://rowthtech.com/api/";

export const GetAPI = `${API}getAllBlogs?`;
export const PostAPI = `${API}createBlog`;
export const PutAPI = `${API}updateBlog`;
export const DeleteAPI = `${API}deleteBlog`;
export const GETONEAPI = `${API}getBlog`;


export const GetCATEGORY = `${API}`;
export const PostCATEGORY = `${API}category`;
export const PutCATEGORY = `${API}category`;
export const DeleteCATEGORY = `${API}category`;
export const ToggleStatusCATEGORY = `${API}category`;
