import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import HtmlContent from '../components/texttohtml';
import Navbar from "../pages/Navbar";
import Footer from "../pages/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from "react-share";
import Config from "../config";

import '../pages/PreviewPage.css';

const baseUrl = Config.REACT_APP_API_URL;

const PreviewPage = () => {
  const { id } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}getBlog/${id}`);
      setData(response.data.response);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const extractPlainText = (html) => {
    const div = document.createElement('DIV');
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const trimToWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const headingText = extractPlainText(data?.mainHeading || "");
  const trimmedHeading = trimToWords(headingText, 20);
  const paraText = extractPlainText(data?.content || "");
  const trimmedPara = trimToWords(paraText, 20);


  return (
    <>
      <Navbar />
      <Container fluid className="mt-3 py-0"><Link to={'/blog'}><img width="30" height="30" src="https://img.icons8.com/ios-filled/50/circled-left.png" alt="circled-left" /></Link></Container>
      <Container fluid='sm' className="my-md-2">
        <Row className="d-flex align-items-center justify-content-center">
          <Col sm={9}>
            <HtmlContent content={data?.mainHeading}></HtmlContent>
            <img className="w-100 rounded mt-3" src={data?.image} alt="rowthtech_img" />
            <div className="mt-3">
              <HtmlContent content={data?.content}></HtmlContent>
            </div>
            <div className="w-100 d-flex flex-column justify-content-center align-items-center py-md-4 mt-5 main_lines">
              <h4>Like the blog? Spread the word</h4>
              <ul className="blogHeadSocial d-flex flex-row gap-4 mt-3 mb-0">
                <li>
                  <FacebookShareButton
                    className="share"
                    hashtag={`${trimmedHeading}---`}
                    url={`https://www.rowthtech.com/readmore/${data?._id}`}
                  >
                    <FacebookIcon className="img" size={32} round={true} />
                  </FacebookShareButton>
                </li>
                <li>
                  <TwitterShareButton
                    className="share"
                    title={`${trimmedHeading} - ${trimmedPara}`}
                    url={`https://www.rowthtech.com/readmore/${data?._id}`}
                  >
                    <TwitterIcon className="img" size={32} round={true} />
                  </TwitterShareButton>
                </li>
                <li>
                  <LinkedinShareButton
                    className="share"
                    title={headingText}
                    url={`https://www.rowthtech.com/readmore/${data?._id}`}
                  >
                    <LinkedinIcon className="img" size={32} round={true} />
                  </LinkedinShareButton>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>

  );
};

export default PreviewPage;
