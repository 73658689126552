import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useGetUserQuery } from "../../state/api";

const Layout = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  return (
      <div className="row mx-0 px-0 d-flex flex-row">
        <div style={{minHeight:"100vh",width:'14.3%'}}>
          <Sidebar
            user={data || {}}
            isNonMobile={isNonMobile}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </div>
        <div style={{width:'85.7%'}}>
          <div className="row">
            <div className="col-12">
              <Outlet />
            </div>
          </div>
        </div>
    </div>
  );
};

export default Layout;
