import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { LuPencilLine } from 'react-icons/lu';
import axios from 'axios';
import "../index.css";
import HtmlContent from '../components/texttohtml';
import CKEditorComponent from '../components/CKEditorComponent';
import { Link, useParams } from 'react-router-dom';
import { PutAPI, GETONEAPI } from '../pages/api/Allapi';

const Userpage = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [editingSection, setEditingSection] = useState(null);
  const [editingBlog, setEditingBlog] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [saving, setSaving] = useState(false);
  const [urlError, setUrlError] = useState('');
  const [imageError, setImageError] = useState('');

  const { id } = useParams();

  const handleClose = () => {
    setShow(false);
    setEditingSection(null);
    setSelectedImage(null);
    setUrlError('');
    setImageError('');
  };

  const handleShow = (blog, section) => {
    if (section === 'mainHeading') {
      setEditingBlog(blog.mainHeading);
    } else if (section === 'content') {
      setEditingBlog(blog.content);
    } else if (section === 'image') {
      setEditingBlog(blog.image);
    } else if (section === 'imageSmall') {
      setEditingBlog(blog.imageSmall);
    } else if (section === 'url') {
      setEditingBlog(blog.url);
    }
    setEditingSection(section);
    setShow(true);
  };

  const handleChange = (value) => {
    setEditingBlog(value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setEditingBlog(URL.createObjectURL(file));
  };

  const validateUrl = (url) => {
    const urlPattern = /^[^\s/]+$/;
    return !!urlPattern.test(url) && !url.includes('/');
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${GETONEAPI}/${id}`);
      setBlogs([response.data.response]);
      setLoading(false);
    } catch (error) {
      setError('Error fetching data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleUpdate = async () => {
    setSaving(true);
    let data = {};
    const blogId = blogs[0]._id;

    if (editingSection === 'mainHeading') {
      data = { mainHeading: editingBlog };
    } else if (editingSection === 'content') {
      data = { content: editingBlog };
    } else if (editingSection === 'image' && selectedImage) {
      const formData = new FormData();
      formData.append('image', selectedImage);
      try {
        const uploadResponse = await axios.put(`${PutAPI}/${blogId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (uploadResponse && uploadResponse.data && uploadResponse.data.imageUrl) {
          data = { image: uploadResponse.data.imageUrl };
        }
      } catch (error) {
        console.error('Error uploading image:', error.response ? error.response.data : error.message);
        setSaving(false);
        return;
      }
    } else if (editingSection === 'imageSmall' && selectedImage) {
      const formData = new FormData();
      formData.append('imageSmall', selectedImage);
      try {
        const uploadResponse = await axios.put(`${PutAPI}/${blogId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (uploadResponse && uploadResponse.data && uploadResponse.data.imageUrl) {
          data = { imageSmall: uploadResponse.data.imageUrl };
        }
      } catch (error) {
        console.error('Error uploading image:', error.response ? error.response.data : error.message);
        setSaving(false);
        return;
      }
    } else if (editingSection === 'url') {
      if (!validateUrl(editingBlog.trim())) {
        setUrlError('Invalid URL format');
        setSaving(false);
        return;
      }
      data = { url: editingBlog.trim() };
    }

    try {
      await axios.put(`${PutAPI}/${blogId}`, data);
      await fetchData();
      handleClose();
    } catch (error) {
      console.error('Error updating blog:', error.response ? error.response.data : error.message);
    } finally {
      setSaving(false);
    }
  };


  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div className="featured-article" style={{ maxHeight: '100vh', overflowY: 'scroll' }}>
        <div className="container-fluid px-0">
          <h4 className='py-md-4 mb mx-md-4 border_shadow fw-bolder'>
            <Link to={'/blog'}><img width="30" height="30" src="https://img.icons8.com/ios-filled/50/circled-left.png" alt="circled-left" /></Link>
            &nbsp;&nbsp;Edit Blog</h4>
          <div className="row mb-5 px-0 mx-3 mt-3 position-relative">
            {blogs.map(blog => (
              <div className="col-12 col-md-12 mt-3" key={blog.id}>
                <div className="entry-content d-flex">
                  <h5 > <strong> Edit Url Of Blog : </strong> </h5>
                  <ul className='d-flex justify-content-start align-items-center'> https://www.rowthtech.com/blog/<HtmlContent content={blog.url} /></ul>&nbsp;&nbsp;
                  <p>
                    <LuPencilLine style={{ cursor: 'pointer' }} onClick={() => handleShow(blog, 'url')} />
                  </p>
                </div>
                <div className="d-flex mt-3">
                  <HtmlContent content={blog.mainHeading} /> &nbsp;&nbsp;
                  <p>
                    <LuPencilLine style={{ cursor: 'pointer' }} onClick={() => handleShow(blog, 'mainHeading')} />
                  </p>
                </div>
                <div className='row mt-3'>
                  <div className='col-7'> <h6><strong>Edit Read More Page Image <span className='text-danger'>(1079 × 599 px)*</span> :</strong> </h6></div>
                  <div className='col-5'> <h6><strong>Edit Blog Page Image <span className='text-danger'>(180 × 112 px)*</span> :</strong> </h6></div>
                </div>
                <div className="blogImgWrap d-flex justify-content-start align-items-start">
                  <div className="post-thumbnail col-7 d-flex justify-content-start">
                    <img width="80%" height="10%" src={blog.image} alt="rowthtech_img" />
                    <p>
                      <LuPencilLine style={{ cursor: 'pointer' }} onClick={() => handleShow(blog, 'image')} />
                    </p>
                  </div>
                  <div className="post-thumbnail col-4 d-flex">
                    <img width="80%" height="10%" src={blog?.imageSmall} alt="rowthtech_img" />
                    <p>
                      <LuPencilLine style={{ cursor: 'pointer' }} onClick={() => handleShow(blog, 'imageSmall')} />
                    </p>
                  </div>
                </div>
                <h4 className='mt-5'><strong>Edit content:</strong></h4>
                 <div className="entry-content for_images d-flex" style={{ maxWidth: '100%' }}>
                  <HtmlContent content={blog.content} />
                  <p>
                    <LuPencilLine style={{ cursor: 'pointer' }} onClick={() => handleShow(blog, 'content')} />
                  </p>
                </div>

              </div>
            ))}
            <div className='d-flex justify-content-end align-items-end'>
              <Link to={{ pathname: `/previewPage/${id}`, state: { blogs } }}>
                <button className='text-white p-2 border-0 rounded-3 shadow px-5 priview_btn' style={{ backgroundColor: '#ff9827' }}>
                  Preview
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingSection === 'image' || editingSection === 'imageSmall' ? (
            <div>
              <label>Image:</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="form-control"
              />
              {imageError && <p className="text-danger">{imageError}</p>}
              {editingBlog && (
                <div className="mt-3">
                  <img src={editingBlog} alt="Current" style={{ width: '100%' }} />
                </div>
              )}
            </div>
          ) : editingSection === 'url' ? (

            <><div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon3">https://example.com/users/</span>
              </div>
              <input className='form-control'
                type="text"
                value={editingBlog}
                onChange={(e) => {
                  setUrlError('');
                  handleChange(e.target.value);
                }} />
              {urlError && <p className="text-danger">{urlError}</p>}
            </div>

            </>
          ) : (
            <CKEditorComponent content={editingBlog} onChange={handleChange} />
          )}
        </Modal.Body>
        <Modal.Footer className='d-flex flex-row'>
          <Button variant="primary" className='w-25' onClick={handleUpdate} disabled={saving}>
            {saving ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Userpage;
