import { Box, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { FaPersonBreastfeeding } from "react-icons/fa6";
import { IoIosLogOut } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import logo from "../Assets/image.png";
import CategoryIcon from "@mui/icons-material/Category";

const Sidebar = ({ user, drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handelRoutes = (route) => {
    navigate(route);
  };

  return (
    <Box
      component="nav"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
        minHeight: "100vh",
        width: drawerWidth, // Apply the width dynamically
        flexShrink: 0, // Fix the sidebar's position in the layout
      }}
    >
      <Box mx="2rem">
        <FlexBetween>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="0.5rem"
            className="mt-4 pb-3"
          >
            <Typography variant="h4" fontWeight="bold">
              <Box
                component="img"
                alt="profile"
                src={logo}
                width="100%"
                sx={{ objectFit: "cover" }}
              />
            </Typography>
          </Box>
          {!isNonMobile && (
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              <ChevronLeft />
            </IconButton>
          )}
        </FlexBetween>
      </Box>
      <List className="mt-2">
        {/* Blog */}
        <ListItem
          button
          onClick={() => handelRoutes("/blog")}
          style={{ backgroundColor: location.pathname === "/blog" ? "rgb(249, 160, 4)" : "",color: location.pathname === "/blog" ? "white" : "Black" }}
        >
          <FaPersonBreastfeeding className="me-4" />
          <ListItemText primary="Blog" />
        </ListItem>
        {/* Category */}
        <ListItem
          button
          onClick={() => handelRoutes("/category")}
          style={{ backgroundColor: location.pathname === "/category" ? "rgb(249, 160, 4)" : "",color: location.pathname === "/category" ? "white" : "Black" }}
        >
          <CategoryIcon className="me-3" />
          <ListItemText primary="Category" />
        </ListItem>
        {/* Logout */}
        <ListItem
          button
          onClick={() => handelRoutes("/login")}
          style={{ backgroundColor: location.pathname === "/login" ? "rgb(249, 160, 4)" : "" }}
        >
          <IoIosLogOut className="me-4" />
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );
};

export default Sidebar;
